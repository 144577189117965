@import './Variables.scss';

.scrollTop:hover {
  transition: 300ms !important;
  opacity: 1;
  transform: scale(1.1);
}

.scrollTop {
  display: flex;
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: 300ms !important;
  opacity: 0.6;
  font-size: 50px !important;
  color: $black-color;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.5;
  }
}