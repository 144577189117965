@import './Variables.scss';

.career,
.secondCareer,
.thirdCareer {
  background: url(../../public/svg/fundoFigura.svg) no-repeat center center fixed;
  height: 100%;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow-x: hidden;
}

.rowSecondCareer {
  background: url(../../public/svg/fundoCareer.svg);
  height: 100%;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 0px 0px 75px;
}

.presentation {
  color: $purble-color;
  font-size: 0.8rem;
}

.tituloPresentation {
  color: $darkBlue-color;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
  padding: 15px 0;
}

.textoPresentation {
  margin-top: 55px;
  font-weight: 300;
  color: $black-color;
}

.tituloCareer {
  color: $darkBlue-color;
  font-size: 1.8rem;
  font-weight: 300;
  text-align: center;
  left: 20px;
  position: relative;
}

.cardCareer {
  background-color: $darkBlue-color-l;
  color: $white-color;
  position: relative;
  left: 25px;
  padding: 25px 25px;
}

.imgCardThirdCareer,
.cardCareer {
  transition: 300ms;

  &:hover {
    box-shadow: 3px 3px 7px $black-color;
    transition: 300ms;
    transform: scale(1.02);
  }
}

.textCardCareer {
  font-size: 0.8rem;
}

.imgCardCareer {
  padding-bottom: 10px;
}

.hashCareer {
  color: $cyan-color;
  font-size: 3rem;
  font-weight: 700;
}

.areaText {
  margin-top: 35px;
}

.textoSecondCareer {
  color: $white-color;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.5;
}

.textThirdCareer {
  color: #4d4d4d;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 1.2;
}

.areaLanguage {
  width: 100%;
  text-align: justify;
}

.language {
  color: $darkBlue-color;
  font-size: 36px;
  font-weight: 700;
  letter-spacing: 6px;
  line-height: 1.1;
}

.separatorTxt {
  margin-left: 5px;
  border: 3px solid $darkBlue-color;
}

@media screen and (min-width: 1920px) {
  .presentation {
    font-size: 1.2rem;
  }

  .tituloPresentation {
    font-size: 4rem;
  }

  .textoPresentation {
    font-size: 1.3rem;
  }

  .tituloCareer {
    font-size: 3rem;
  }

  .textCardCareer {
    font-size: 1.3rem;
  }

  .textoSecondCareer {
    font-size: 2.2rem;
  }

  .hashCareer {
    font-size: 4rem;
  }

  .textThirdCareer {
    font-size: 2.5rem;
  }

  .language {
    font-size: 3rem;
  }
}

@media screen and (max-width: 1024px) {
  .tituloCareer {
    left: 0px;
  }

  .cardCareer {
    left: 0px;
  }

  .areaText {
    margin-bottom: 35px;
  }

  .rowSecondCareer {
    padding: 25px;
  }
}

@media screen and (max-width: 480px) {
  .presentation {
    font-size: 0.8rem;
  }

  .tituloPresentation {
    font-size: 2.3rem;
  }

  .textoPresentation {
    font-size: 1rem;
  }

  .textThirdCareer {
    font-size: 1.2rem;
  }

  .language {
    font-size: 26px;
  }

  .textoSecondCareer {
    font-size: 1.2rem;
  }

  .hashCareer {
    font-size: 2.6rem;
  }

}