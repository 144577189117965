@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');

* {
  font: "Roboto";
}

html {
  scroll-behavior: smooth;
}

.slick-slide {
  padding: 0px 5px
}