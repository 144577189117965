@import './Variables.scss';

.job {
  background: url(../../public/svg/fundoFigura.svg) no-repeat center center fixed;
  height: 100%;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow-x: hidden;
}

.presentation {
  color: #420d55;
  font-size: 0.8rem;
}

.tituloPresentation {
  color: $darkBlue-color;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
  padding: 15px 0;
}

.textoPresentation {
  margin-top: 55px;
  font-weight: 300;
  color: $black-color;
}

.tituloJob {
  color: $darkBlue-color;
  font-size: 1.8rem;
  font-weight: 300;
  text-align: center;
  left: 20px;
  position: relative;
}

.cardJob:hover {
  transition: 300ms;
  transform: scale(1.01);
  box-shadow: 3px 3px 7px #951589;
}

.cardJob {
  background-color: #951589;
  color: $white-color;
  position: relative;
  padding: 20px;
  cursor: pointer;
  transition: 300ms;
}

.textCardJob {
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 700;
}

.areaSeeMore {
  margin-top: 50px;
  position: relative;
}

.seeMore:hover {
  transition: 300ms;
  transform: scale(1.01);
  box-shadow: 3px 3px 7px $darkBlue-color;
}

.seeMore {
  color: $white-color;
  background-color: $darkBlue-color;
  padding: 2px 105px;
  letter-spacing: 5px;
  font-size: 1.2rem;
  font-weight: 700;
  cursor: pointer;
  transition: 300ms;
}

.separator::before {
  content: "";
  flex: 1;
  height: 3px;
  background: $darkBlue-color-l;
}

.separator::after {
  content: "";
  flex: 1;
  height: 3px;
  background: $darkBlue-color-l;
}

.separator {
  text-align: center;
  display: flex;
  align-items: center;
}

.icon {
  font-size: 32px;
}

.imgCardJob {
  padding-bottom: 10px;
}

.hashJob {
  color: $cyan-color;
  font-size: 3rem;
  font-weight: 700;
}

.areaText {
  margin-top: 35px;
}

@media screen and (min-width: 1920px) {
  .presentation {
    font-size: 1.2rem;
  }

  .tituloPresentation {
    font-size: 4rem;
  }

  .textoPresentation {
    font-size: 1.3rem;
  }

  .icon {
    font-size: 3rem;
  }

  .seeMore {
    font-size: 2rem;
  }

  .textCardJob {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 768px) {
  .textoPresentation {
    margin-top: 15px;
  }

  .areaSeeMore {
    margin-bottom: 50px;
  }

  .seeMore {
    padding: 2px 85px;
  }
}

@media screen and (max-width: 480px) {
  .presentation {
    font-size: 0.8rem;
  }

  .tituloPresentation {
    font-size: 2.3rem;
  }

  .textoPresentation {
    font-size: 1rem;
  }

  .textCardJob {
    font-size: 0.8rem;
  }

  .seeMore {
    padding: 5px 35px;
    font-size: 0.8rem;
  }
}