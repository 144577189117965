@import './Variables.scss';

.backGroundImage {
  background: url(../../public/svg/fundo.svg) no-repeat center center fixed;
  height: 100vh;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow-x: hidden;
}

.titulo {
  font-size: 3rem;
  color: $white-color;
  font-weight: 700;
}

.tituloCor {
  color: $cyan-color;
  font-size: 3rem;
  font-weight: 700;
}

.subTitulo {
  color: $white-color;
  font-size: 1.2rem;
}

@media screen and (min-width: 1920px) {

  .titulo,
  .tituloCor {
    font-size: 4rem;
  }

  .subTitulo {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 480px) {

  .titulo,
  .tituloCor {
    font-size: 1.9rem;
  }

  .subTitulo {
    font-size: 0.9rem;
  }

  .backGroundImage {
    background: linear-gradient(180deg, #304486 0%, #312c8e 50%, #971488 100%);
  }
}

@media screen and (max-width: 395px) {

  .titulo,
  .tituloCor {
    font-size: 1.7rem;
  }

  .subTitulo {
    font-size: 0.8rem;
  }
}