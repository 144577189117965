@import './Variables.scss';

.btnSend {
  background-color: $darkBlue-color;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 5px 0;
  min-width: 220px;
}

.separator::before {
  content: "";
  flex: 1;
  height: 3px;
  background: $darkBlue-color-l;
}

.separator::after {
  content: "";
  flex: 1;
  height: 3px;
  background: $darkBlue-color-l;
}

.separator {
  text-align: center;
  display: flex;
  align-items: center;
}

.error {
  color: red;
  font-size: 0.8rem;
  font-weight: 500;
  background-color: rgb(255, 243, 243);
  padding: 1px 5px;
  margin-top: 3px;
  border-radius: 3px;
}

.sendContact {
  color: green;
  background-color: rgb(222, 255, 222);
  padding: 5px 10px;
  border-radius: 5px;
}

.form {
  > div {
    > label {
      font-size: 1rem;
      font-weight: 500;
      line-height: 18px;
      color: $darkBlue-color;
      margin-bottom: 5px;
    }

    > input {
      background: #ffffff;
      margin: 0;
      padding: 9px 15px;
      font-size: 14px;
      line-height: 18px;
      color: #444444;
      border: 1px solid #dadada;
      border-radius: 6px;
      &:focus {
        box-shadow: none !important;
        outline: none !important;
      }
    }

    > textarea {
      background: #ffffff;
      margin: 0;
      padding: 8px 15px;
      font-size: 14px;
      line-height: 18px;
      color: #444444;
      border: 1px solid #e5e5e5;
      border-radius: 6px;
      &:focus {
        box-shadow: none !important;
        outline: none !important;
      }
    }
  }
}

.tituloPresentation {
  color: $darkBlue-color;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  padding: 15px 0;
}

.linkFooter {
  color: #FFF;
  font-size: 0.9rem;
  line-height: 1.8;
  z-index: 1;
  max-width: 105px;
  cursor: pointer;

  &:hover {
    font-weight: 600;
  }
}

.backGroundImage {
  background: url(../../public/svg/fundoFooter.svg) no-repeat center center fixed;
  height: 50vh;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow-x: hidden;
}

.logoFooter {
  position: relative;
  top: 30px;
}

.titulo {
  color: $white-color;
  font-size: 1.8rem;
  font-weight: 700;
}

.subTitulo {
  color: $cyan-color;
  font-size: 1.8rem;
  font-weight: 700;
}

.tituloSite {
  color: $white-color;
  font-size: 1rem;
  font-weight: 700;
}

.tituloDireito {
  color: $white-color;
  font-size: 1rem;
  font-weight: 700;
  margin: 8px 0 0 0;
}

.tituloEmail {
  color: $white-color;
  font-weight: 400;
  font-size: 1rem;
  margin: 8px 0 0 0;
}

.alignIcon {
  margin: 0 5px;
}

.areaSocialIcon {
  transition: 300ms;

  &:hover {
    border-radius: 50%;
    transition: 300ms;
    transform: scale(1.1);
  }
}

@media screen and (min-width: 1920px) {

  .titulo,
  .subTitulo {
    font-size: 2.8rem;
  }

  .tituloSite {
    font-size: 1.6rem;
  }

  .tituloDireito {
    font-size: 1.3rem;
  }

  .tituloEmail {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 1199px) {
  .backGroundImage {
    height: 80vh;
  }
}

@media screen and (max-width: 480px) {

  .titulo,
  .subTitulo {
    font-size: 1.8rem;
  }

  .tituloSite {
    font-size: 0.9rem;
  }

  .tituloDireito {
    font-size: 0.9rem;
  }

  .tituloEmail {
    font-size: 0.9rem;
  }

  .backGroundImage {
    background: linear-gradient(9deg, #331497 0%, #312c8e 40%, #331497 100%);
  }
}