@import './Variables.scss';

.about {
  background: url(../../public/svg/fundoFigura.svg) no-repeat center center fixed;
  height: 100%;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow-x: hidden;
}

.presentation {
  color: #420d55;
  font-size: 0.8rem;
}

.tituloPresentation {
  color: $darkBlue-color;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
  padding: 15px 0;
}

.textoPresentation {
  margin-top: 55px;
  font-weight: 300;
  color: $black-color;
}

.nome {
  color: $darkBlue-color-l;
  font-size: 1rem;
  font-weight: 700;
  margin: 15px 0 3px 0;
}

.areaSocialIcon {
  cursor: pointer;
  margin-top: 15px;
  transition: 300ms;

  &:hover {
    border-radius: 50%;
    transition: 300ms;
    transform: scale(1.1);
  }
}

.funcao {
  color: $lightPurble-color;
  font-size: 0.8rem;
  font-weight: 500;
}

@media screen and (min-width: 1920px) {
  .presentation {
    font-size: 1.2rem;
  }

  .tituloPresentation {
    font-size: 4rem;
  }

  .textoPresentation {
    font-size: 1.3rem;
  }
}

@media screen and (max-width: 480px) {
  .presentation {
    font-size: 0.8rem;
  }

  .tituloPresentation {
    font-size: 2.3rem;
  }

  .textoPresentation {
    font-size: 1rem;
  }
}