@import "./Variables.scss";

.nav {
  position: absolute;
  background-color: transparent;
  width: 100%;
  z-index: 50;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3rem 6rem;
}

.navMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navItem {
  margin-left: 3rem;
  display: inline-block;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 3px;
    bottom: -5px;
    left: 0;
    background-color: $white-color;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  &:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

  > a {
    display: inline-block;
    position: relative;
    font-size: 1rem;
    font-weight: 700;
    color: $white-color;
    transition: 300ms;
  }
}

.btnLink {
  display: inline-block;
  position: relative;
  margin-left: 3rem;
  font-size: 1rem;
  font-weight: 700;
  color: $darkBlue-color;
  border-radius: 5px;
  padding: 5px 15px;
  background-color: $white-color;
  box-shadow: 2px 2px 10px $darkBlue-color;
  transition: 300ms;

  &:hover {
    transition: 300ms;
    box-shadow: 5px 5px 10px $darkBlue-color;
  }
}

.panel {
  max-width: 500px;
  background-color: #455073;
  background-size: 117px 188px;
  transition: all 0.4s cubic-bezier(0.42, 0, 0.34, 1.01);
  position: fixed;
  z-index: 5;
  right: -100%;
  top: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  opacity: 0;
  padding: 3em;
  display: flex;

  .open {
    visibility: visible;
    width: 100%;
    right: 0;
    opacity: 1;
  }
}

.mobileLogo {
  padding: 25px 65px;
}

.areaBars {
  > button {
    padding: 0px;
    margin: 0px;
  }
}

.areaBars svg {
  width: 40px !important;
  height: 40px !important;
  fill: $white-color;
}

.bars {
  color: $white-color;
  font-size: 35px;
}

@media screen and (max-width: 768px) {
  .navbar {
    padding: 3rem 3rem;
  }
}

@media screen and (max-width: 575px) {
  .navbar {
    padding: 3rem 1rem;
  }

  .logoNav {
    width: 50%;
  }
}
