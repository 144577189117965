@import './Variables.scss';

.about {
  background: url(../../public/svg/fundoPortfolio.svg) no-repeat center center fixed;
  height: 100%;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow-x: hidden;
}

.portfolio {
  color: $black-grey-color;
  font-size: 0.8rem;
}

.linkImage {
  cursor: pointer;
  transition: 300ms;

  &:hover {
    transform: scale(1.02);
    transition: 300ms;
    box-shadow: 3px 3px 8px $black-color;
  }
}

.tituloPortfolio {
  color: $black-grey-color;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
  padding: 15px 0;
}

.textPortfolio {
  font-weight: 300;
  font-size: 1.8rem;
  color: $black-color;
}

@media screen and (min-width: 1920px) {
  .portfolio {
    font-size: 1.2rem;
  }

  .tituloPortfolio {
    font-size: 4rem;
  }

  .textPortfolio {
    font-size: 2.2rem;
  }
}

@media screen and (max-width: 480px) {
  .portfolio {
    font-size: 0.8rem;
  }

  .tituloPortfolio {
    font-size: 2.3rem;
  }

  .textPortfolio {
    font-size: 1rem;
  }
}