// Colors
$white-color: #ffffff;
$cyan-color: #1df5ec;
$lightPurble-color-1: #951589;
$lightPurble-color: #801780;
$purble-color: #420D55;
$darkPurble-color: #22082b;
$darkBlue-color: #331597;
$darkBlue-color-l: #330097;
$grey-color: #4D4D4D;
$black-grey-color: #171414;
$black-color: #000000;

// transition
$transition-duration: 300ms;

// Font Weight
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;
$font-black: 900;

// font size
$size-small: 0.8rem;
$size-regular: 1rem;
$size-medium: 1.2rem;
$size-extra-medium: 1.8rem;
$size-big: 3rem;