@import './Variables.scss';

.blog {
  background: url(../../public/svg/fundoFigura.svg) no-repeat center center fixed;
  height: 100%;
  width: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overflow-x: hidden;
}

.presentation {
  color: #420d55;
  font-size: 0.8rem;
}

.tituloPresentation {
  color: $darkBlue-color;
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
  padding: 15px 0;
}

.textoPresentation {
  margin-top: 55px;
  font-weight: 300;
  color: $black-color;
}

.tituloBlog {
  color: $darkBlue-color;
  font-size: 1.8rem;
  font-weight: 300;
  text-align: center;
  left: 20px;
  position: relative;
}

.cardBlog {
  color: $white-color;
  position: relative;
  cursor: pointer;
}

.areaTextBlog {
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
}

.cardItemBlog {
  padding: 10px 5px;
}

.textCardBlog {
  display: flex;
  text-align: left;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 500;
}

.textCardBlogDate {
  display: flex;
  text-align: left;
  align-items: center;
  margin-top: 20px;
}

.arrowLeft {
  color: #330098;
  font-size: 45px;
  position: absolute;
  top: 50%;
  left: -37px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}

.arrowRight {
  color: #330098;
  font-size: 45px;
  position: absolute;
  top: 50%;
  right: -40px;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}

@media screen and (min-width: 1920px) {
  .presentation {
    font-size: 1.2rem;
  }

  .tituloPresentation {
    font-size: 4rem;
  }

  .textoPresentation {
    font-size: 1.3rem;
  }

  .arrowLeft {
    font-size: 65px;
    left: -55px;
  }

  .arrowRight {
    font-size: 65px;
    right: -55px;
  }

  .textCardBlog {
    font-size: 1.8rem;
  }

  .textCardBlogDate {
    font-size: 1.2rem;
  }
}

@media screen and (max-width: 480px) {
  .presentation {
    font-size: 0.8rem;
  }

  .tituloPresentation {
    font-size: 2.3rem;
  }

  .textoPresentation {
    font-size: 1rem;
  }
}